<template>
  <v-row justify="end" align="end">
    <v-menu
    bottom
    origin="center center"
    transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="idAcaoMassiva"
          @click="carregarVariaveis()"
          icon>
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>
      <v-list v-if="this.status !== ''">
        <!-- <v-list-item v-if="mostrarAcompanhamento" @click="abrirAcompanhamento">
          <v-list-item-action>
              <v-icon>assignment_turned_in</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $tc('label.acompanhamento') }}</v-list-item-title>
        </v-list-item> -->

        <v-list-item @click="acaoModal(true,'SOLICITAR')" v-if="exibeSolicitarAprovacao">
          <v-list-item-action>
              <v-icon>send</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t('label.solicitar_aprovacao') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="acaoModal(true,'APROV')" v-if="exibeBotoes">
          <v-list-item-action>
              <v-icon>thumb_up</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t('label.aprovar') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="acaoModal(true,'EM_ANALISE')" v-if="exibeBotoes">
          <v-list-item-action>
              <v-icon>announcement</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t('label.enviar_analise') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="acaoModal(true,'REPROV')" v-if="exibeBotoes">
          <v-list-item-action>
              <v-icon>thumb_down</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t('label.reprovar') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="acaoModal(true,'CANCELAR')" v-if="exibeBotoes">
          <v-list-item-action>
              <v-icon>cancel</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ `${$tc('label.cancelar', 1)} ${$tc('title.fluxo_aprovacao')}` }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="acaoModal(true,'RESGATAR')" v-if="exibeResgataVencido">
          <v-list-item-action>
              <v-icon>check_circle</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $tc('message.deseja_resgatar_apuracao', 1) }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="exibirFluxoNaoEncontrado">
          <v-list-item-action>
              <v-icon>info</v-icon>
          </v-list-item-action>
          <v-list-item-title>Fluxo não encontrado</v-list-item-title>
        </v-list-item>
      </v-list>
  </v-menu>
  <v-dialog v-model="showModal" max-width="800">
    <v-card>
      <v-card-title>
      <span class="bar" style="text-transform: uppercase">
        {{ aprovar ? $tc('label.aprovar') : reprovar ? $tc('label.reprovar') : emAnalise ? $tc('label.enviar_analise') : cancelar ? `${$tc('label.cancelar', 1)} ${$tc('title.fluxo_aprovacao')}` : solicitaAprov ? $t('label.solicitar_aprovacao') : $tc('message.deseja_resgatar_apuracao', 1) }}
      </span>
      </v-card-title>
      <br>
      <v-card-subtitle>
        {{ $tc('title.confirm_acao_aprov_mass') }}
        {{ aprovar ? $tc('label.aprovar') : reprovar ? $tc('label.reprovar') : emAnalise ? $tc('label.enviar_analise') : cancelar ? `${$tc('label.cancelar', 1)} ${$tc('title.fluxo_aprovacao')}` : solicitaAprov ? $t('label.solicitar_aprovacao') : 'Resgatar Apuração' }}
        ?
      </v-card-subtitle>
      <v-card-subtitle v-if="aprovar">
        {{ $t('title.confirm_aprov_apu_massiva_irrev', { idAcaoPrevia: idAcaoMassiva, codAcoes: retornarCodAcoes }) }}
      </v-card-subtitle>
      <v-card-subtitle v-if="emAnalise">
        {{ $t('title.confirm_analise_apu_massiva_irrev', { idAcaoPrevia: idAcaoMassiva, codAcoes: retornarCodAcoes }) }}
      </v-card-subtitle>
      <v-card-subtitle v-if="reprovar">
        {{ $t('title.confirm_reprovar_apu_massiva_irrev', { idAcaoPrevia: idAcaoMassiva, codAcoes: retornarCodAcoes }) }}
      </v-card-subtitle>
      <v-card-subtitle v-if="cancelar">
        {{ $t('title.confirm_cancelar_fluxo_apu_massiva_irrev', { idAcaoPrevia: idAcaoMassiva, codAcoes: retornarCodAcoes }) }}
      </v-card-subtitle>
      <v-card-subtitle v-if="solicitaAprov">
        {{ $t('title.confirm_solicitar_aprov_apu_massiva_irrev', { idAcaoPrevia: idAcaoMassiva, codAcoes: retornarCodAcoes }) }}
      </v-card-subtitle>
      <v-card-subtitle v-if="resgatarVencido">
        {{ $t('title.confirm_resgatar_apu_massiva_irrev', { idAcaoPrevia: idAcaoMassiva, codAcoes: retornarCodAcoes }) }}
      </v-card-subtitle>
      <v-card-text>
        <v-textarea
          id="justificativa"
          name="justificativa"
          :label="`${$tc('label.justificativa', 1)}`"
          v-model="justificativa"
          auto-grow
          autofocus
          :rules="[rules.required]"
          rows="1"
        ></v-textarea>

        <v-row>
          <v-col cols="10" v-if="resgatarVencido">
            <metadados-campo-dinamico-data
              :metadados="cfgCampoProrrogacao"
              v-model="dtaProrrogacao"
              :somenteLeitura="!resgatarVencido"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-data-table
          :headers="cabecalho"
          :items="apuracoes"
          v-model="selecionados"
          item-key="id"
          show-select>
          <template v-slot:item.id_acao="{ item }">
            {{ item.idAcao }}
          </template>
          <template v-slot:item.hierarquia="{ item }">
            {{ item.hierarquiaAcao }}
          </template>
          <template v-slot:item.valor_acao="{ item }">
            {{ item.valorAcao }}
          </template>
          <template v-slot:item.tipo_acao="{ item }">
            {{ item.tipoAcao }}
          </template>
          <template v-slot:item.tipo_verba="{ item }">
            {{ item.tipoVerba }}
          </template>
          <template v-slot:item.vlr_conf_apuracao="{ item }">
            {{ getMoney(item.vlrConfApuracao) }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="acaoModal(false,null)">{{ $t('label.nao') }}</v-btn>
        <v-btn color="primary" v-if="aprovar && selecionados.length > 0 && justificativa"  @click="aprovarApuracao(justificativa)">{{ $t('label.sim') }}</v-btn>
        <v-btn color="primary" v-if="emAnalise && selecionados.length > 0 && justificativa" @click="enviarAnaliseApuracao(justificativa)">{{ $t('label.sim') }}</v-btn>
        <v-btn color="primary" v-if="reprovar && selecionados.length > 0 && justificativa"  @click="reprovarApuracao(justificativa)">{{ $t('label.sim') }}</v-btn>
        <v-btn color="primary" v-if="cancelar && selecionados.length > 0 && justificativa"  @click="cancelarApuracao(justificativa)">{{ $t('label.sim') }}</v-btn>
        <v-btn color="primary" v-if="solicitaAprov && selecionados.length > 0 && justificativa"  @click="iniciaFluxo(justificativa)">{{ $t('label.sim') }}</v-btn>
        <v-btn color="primary" v-if="resgatarVencido && selecionados.length > 0 && justificativa && dtaProrrogacao"  @click="resgatarApuracao(justificativa, dtaProrrogacao)">{{ $t('label.sim') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"/>
</v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { getMoney } from '@/produto/common/functions/helpers';
import MetadadosCampoDinamicoData from '@/produto/shared-components/metadados/campos-dinamicos/MetadadosCampoDinamicoData';
import { listarApuracoesSuzano } from '@/common/resources/apuracao-suzano';
import Acompanhamento from '../../shared-components/workflow/Acompanhamento';

export default {
  name: 'ApuracaoAcaoMassivaFluxo',
  components: {
    Acompanhamento,
    MetadadosCampoDinamicoData,
  },
  props: {
    status: '',
    idAcaoMassiva: null,
    exibirAcompanhamento: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    exibeBotoes() {
      return this.aprovadorFluxo && this.apuracoes.length;
    },
    exibeSolicitarAprovacao() {
      return this.solicitanteFluxo && this.apuracoes.length;
    },
    exibeResgataVencido() {
      return this.solicitanteFluxoResgate && this.apuracoes.length;
    },
    mostrarAcompanhamento() {
      return this.exibirAcompanhamento && !this.isStatusAguardandoApuracao
        && !this.isStatusApuracaoPrevia && !this.isStatusCancelado
        && !this.isStatusVencido && this.apuracoes.length;
    },
    exibirFluxoNaoEncontrado() {
      return !this.exibeBotoes && !this.exibeSolicitarAprovacao && !this.exibeResgataVencido
        && (this.validacoes === 3);
    },
    isStatusAguardandoAprovacao() {
      return this.status === 'AGUARDANDO_APROVACAO';
    },
    isStatusAguardandoApuracao() {
      return this.status === 'AGUARDANDO_APURACAO';
    },
    isStatusApuracaoPrevia() {
      return this.status === 'APURACAO_PREVIA';
    },
    isStatusPendente() {
      return this.status === 'PENDENTE';
    },
    isStatusVencido() {
      return this.status === 'VENCIDO';
    },
    isStatusEmAnalise() {
      return this.status === 'EM_ANALISE';
    },
    isStatusCancelado() {
      return this.status === 'CANCELADO';
    },
    retornarCodAcoes() {
      let codAcoes = '';

      this.selecionados.forEach((ap) => {
        if (codAcoes === '') {
          codAcoes = ap.idAcao.toString();
        } else {
          codAcoes += `, ${ap.idAcao.toString()}`;
        }
      });

      return codAcoes;
    },
  },
  data() {
    return {
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      aprovar: false,
      reprovar: false,
      emAnalise: false,
      cancelar: false,
      solicitaAprov: false,
      resgatarVencido: false,
      dtaProrrogacao: null,
      showModal: false,
      justificativa: null,
      selecionados: [],
      apuracoes: Array,
      buscando: false,
      aprovadorFluxo: false,
      solicitanteFluxo: false,
      solicitanteFluxoResgate: false,
      validacoes: 0,
      statusPassos: {},
      cfgCampoProrrogacao: {
        vlrObrigatorio: true,
        nomCampo: this.$t('label.data_prorrogacao'),
        desAtributos: {
          dtaMinima: 'amanha',
        },
      },
      cabecalho: [
        {
          text: this.$tc('label.cod_acao', 1), align: 'left', value: 'id_acao', sortable: true, width: '20%',
        },
        {
          text: this.$tc('label.hierarquia', 1), align: 'left', value: 'hierarquia', sortable: true, width: '20%',
        },
        {
          text: this.$tc('label.valor_acao', 1), align: 'left', value: 'valor_acao', sortable: true, width: '20%',
        },
        {
          text: this.$tc('label.tipo_acao', 1), align: 'left', value: 'tipo_acao', sortable: true, width: '20%',
        },
        {
          text: this.$tc('label.tipo_de_verba', 1), align: 'left', value: 'tipo_verba', sortable: true, width: '20%',
        },
        {
          text: this.$tc('label.valor_pagamento_calculado', 1), align: 'left', value: 'vlr_conf_apuracao', sortable: false, width: '20%',
        },
      ],
      pagination: {
        itemsPerPage: 10,
      },
      totalPage: 0,
      lista: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    getMoney,
    acaoModal(boolean, tipo) {
      this.showModal = boolean;
      this.limpaVariaveis();
      switch (tipo) {
        case 'APROV':
          this.apuracoes = this.apuracoes.filter((el) => el.status === 'AGUARDANDO_APROVACAO').map((c) => c);
          this.aprovar = true;
          break;
        case 'EM_ANALISE':
          this.apuracoes = this.apuracoes.filter((el) => el.status === 'AGUARDANDO_APROVACAO').map((c) => c);
          this.emAnalise = true;
          break;
        case 'REPROV':
          this.apuracoes = this.apuracoes.filter((el) => el.status === 'AGUARDANDO_APROVACAO').map((c) => c);
          this.reprovar = true;
          break;
        case 'CANCELAR':
          this.apuracoes = this.apuracoes.filter((el) => el.status === 'AGUARDANDO_APROVACAO').map((c) => c);
          this.cancelar = true;
          break;
        case 'SOLICITAR':
          this.apuracoes = this.apuracoes.filter((el) => ((el.status === 'AGUARDANDO_APURACAO')
            || (el.status === 'EM_ANALISE'))).map((c) => c);
          this.solicitaAprov = true;
          break;
        case 'RESGATAR':
          this.apuracoes = this.apuracoes.filter((el) => el.status === 'VENCIDO').map((c) => c);
          this.resgatarVencido = true;
          break;
        default:
          return 'N/A';
      }
      return this.showModal;
    },
    carregarVariaveis() {
      this.validacoes = 0;
      this.buscar();
    },
    limpaVariaveis() {
      this.aprovar = false;
      this.emAnalise = false;
      this.reprovar = false;
      this.cancelar = false;
      this.solicitaAprov = false;
      this.resgatarVencido = false;
      this.justificativa = null;
      this.validacoes = 0;
    },
    resgatarApuracao(justificativa, dtaProrrogacao) {
      Promise.all(this.selecionados.map((c) => {
        const idApuracao = c.id;
        return this.apuracaoAcaoResource.resgatar({ idApuracao }, { justificativa, dtaProrrogacao });
      })).then(() => {
        this.showModal = false;
        this.$toast(this.$t('message.apuracao_resgatada'));
        this.$emit('AprovacaoMassivaApuracao_sucesso');
        this.$emit('LimpaSelecionados_sucesso');
      }).catch((err) => {
        this.showModal = false;
        this.$error(this, err);
      });
    },
    iniciaFluxo(justificativa) {
      Promise.all(this.selecionados.map((c) => {
        const idApuracao = c.id;
        return this.workflowApuracaoAcaoResource.iniciaFluxo({ idApuracao },
          { idApuracao, observacao: justificativa });
      })).then(() => {
        this.showModal = false;
        this.$toast(this.$t('message.solicitacao_aprovacao'));
        this.$emit('AprovacaoMassivaApuracao_sucesso');
        this.$emit('LimpaSelecionados_sucesso');
      }).catch((err) => {
        this.showModal = false;
        this.$error(this, err);
      });
    },
    aprovarApuracao(justificativa) {
      Promise.all(this.selecionados.map((c) => {
        const idApuracao = c.id;
        return this.workflowApuracaoAcaoResource.aprovarPasso({ idApuracao },
          { observacao: justificativa });
      })).then(() => {
        this.showModal = false;
        this.$toast(this.$t('message.apuracao_aprov_mass_sucesso'));
        this.$emit('AprovacaoMassivaApuracao_sucesso');
        this.$emit('LimpaSelecionados_sucesso');
      })
        .catch((err) => {
          this.showModal = false;
          this.$error(this, err);
        });
    },
    reprovarApuracao(justificativa) {
      Promise.all(this.selecionados.map((c) => {
        const idApuracao = c.id;
        return this.workflowApuracaoAcaoResource.reprovar({ idApuracao },
          { observacao: justificativa });
      })).then(() => {
        this.showModal = false;
        this.$toast(this.$t('message.apuracao_reprov_mass_sucesso'));
        this.$emit('AprovacaoMassivaApuracao_sucesso');
        this.$emit('LimpaSelecionados_sucesso');
      })
        .catch((err) => {
          this.showModal = false;
          this.$error(this, err);
        });
    },
    enviarAnaliseApuracao(justificativa) {
      Promise.all(this.selecionados.map((c) => {
        const idApuracao = c.id;
        return this.workflowApuracaoAcaoResource.enviarAnalise({ idApuracao },
          { observacao: justificativa });
      })).then(() => {
        this.showModal = false;
        this.$toast(this.$t('message.apuracao_em_analise_mass_sucesso'));
        this.$emit('AprovacaoMassivaApuracao_sucesso');
        this.$emit('LimpaSelecionados_sucesso');
      })
        .catch((err) => {
          this.showModal = false;
          this.$error(this, err);
        });
    },
    cancelarApuracao(observacao) {
      Promise.all(this.selecionados.map((c) => {
        const idApuracao = c.id;
        return this.workflowApuracaoAcaoResource.cancelar({ idApuracao }, { observacao });
      })).then(() => {
        this.showModal = false;
        this.$toast(this.$t('message.apuracao_fluxo_cancelado'));
        this.$emit('AprovacaoMassivaApuracao_sucesso');
        this.$emit('LimpaSelecionados_sucesso');
      })
        .catch((err) => {
          this.showModal = false;
          this.$error(this, err);
        });
    },
    buscar() {
      this.consultar(listarApuracoesSuzano, (response) => {
        this.apuracoes = this.verificarUsuarioAprovador(response.data.resposta);
        this.apuracoes = this.verificarUsuarioSolicitante(response.data.resposta);
        this.verificarUsuarioSolicitanteResgate(response.data.resposta);
        setTimeout(this.finalizandoBusca);
      });
    },
    consultar(consulta, sucesso) {
      if (this.buscando) return;
      this.buscando = true;

      const params = {
        cod_acao_massiva: this.idAcaoMassiva,
        ind_fluxo_massivo: true,
        tamanhoPagina: 100,
        numeroPagina: 0,
      };

      consulta(params, this.$resource)
        .then(sucesso)
        .catch((err) => {
          this.finalizandoBusca();
          this.$error(this, err);
        });
    },
    finalizandoBusca() {
      this.buscando = false;
    },
    verificarUsuarioSolicitante(apuracoes) {
      let validado = false;

      const apuracoresFiltradas = apuracoes.filter((el) => ((el.status === 'AGUARDANDO_APURACAO')
        || (el.status === 'EM_ANALISE')));

      if (apuracoresFiltradas.length <= 0) {
        this.validacoes += 1;
      }

      apuracoresFiltradas.map((c) => {
        const idApuracao = c.id;
        this.workflowApuracaoAcaoResource.solicitante({ idApuracao })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
            if (!validado) {
              this.validacoes += 1;
              validado = true;
            }
          }).catch((err) => {
            this.$error(this, err);
          });
        return c;
      });
      return apuracoes;
    },
    verificarUsuarioSolicitanteResgate(apuracoes) {
      this.solicitanteFluxoResgate = !!this.getAllRoles
        .filter((el) => el === 'APU_RESGATA_VENCIDO').length
        && !!apuracoes.filter((el) => (el.status === 'VENCIDO')).length;

      this.validacoes += 1;
    },
    verificarUsuarioAprovador(apuracoes) {
      this.aprovadorFluxo = false;

      let validado = false;

      const apuracoresFiltradas = apuracoes.filter((el) => el.status === 'AGUARDANDO_APROVACAO');

      if (apuracoresFiltradas.length <= 0) {
        this.validacoes += 1;
      }

      apuracoresFiltradas.map((c) => {
        const idApuracao = c.id;
        this.workflowApuracaoAcaoResource.aprovador({ idApuracao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              c.isSelectable = true;
              this.aprovadorFluxo = true;
            } else {
              c.isSelectable = false;
            }

            if (!validado) {
              this.validacoes += 1;
              validado = true;
            }
          }).catch((err) => {
            this.$error(this, err);
          });
        return c;
      });
      return apuracoes;
    },
    abrirAcompanhamento() {
      const { idApuracao } = this.apuracoes[0];
      this.workflowApuracaoAcaoResource
        .existeFluxoCadastrado({ idApuracao })
        .then((res) => {
          const existeFluxo = res.data;
          if (!existeFluxo) {
            const msg = this.$t('message.pagamento_finalizada_sem_fluxo');
            this.$toast(msg);
            return;
          }
          this.exibeModalAcompanhamento(idApuracao);
        });
    },
    exibeModalAcompanhamento(idApuracao) {
      this.workflowApuracaoAcaoResource.statusPasso({ idApuracao }, { idApuracao })
        .then((res) => {
          if (res.data.passos
            && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
  },
};
</script>
