var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center"}},[_c('div',{staticStyle:{"padding":"8px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":!_vm.disabled,"color":"primary","small":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.acaoModal(true,'APROV')}}},on),[_c('v-icon',[_vm._v("thumb_up")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.aprovar')))])])],1),_c('div',{staticStyle:{"padding":"8px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"fab":"","dark":!_vm.disabled,"color":"primary","small":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.acaoModal(true,'EM_ANALISE')}}},on),[_c('v-icon',[_vm._v("announcement")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.enviar_analise')))])])],1),_c('div',{staticStyle:{"padding":"8px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"fab":"","dark":!_vm.disabled,"color":"primary","small":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.acaoModal(true,'REPROV')}}},on),[_c('v-icon',[_vm._v("thumb_down")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.reprovar')))])])],1)]),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"bar",staticStyle:{"text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.aprovar ? _vm.$tc('label.aprovar') : _vm.reprovar ? _vm.$tc('label.reprovar') : _vm.$tc('label.enviar_analise'))+" ")])]),_c('br'),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$tc('title.confirm_acao_aprov_mass'))+" "+_vm._s(_vm.aprovar ? _vm.$tc('label.aprovar') : _vm.reprovar ? _vm.$tc('label.reprovar') : _vm.$tc('label.enviar_analise'))+" "+_vm._s(_vm.$tc('title.confirm_aprov_mass_apura_selec'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$tc('title.confirm_aprov_mass_irrev'))+" ")]),_c('v-card-text',[(!_vm.aprovar)?_c('v-textarea',{attrs:{"id":"justificativa","name":"justificativa","label":("" + (_vm.$tc('label.justificativa', 1))),"auto-grow":"","autofocus":"","rules":[_vm.rules.required],"rows":"1"},model:{value:(_vm.justificativa),callback:function ($$v) {_vm.justificativa=$$v},expression:"justificativa"}}):_c('v-textarea',{attrs:{"id":"justificativa","name":"justificativa","label":("" + (_vm.$tc('label.justificativa', 1))),"auto-grow":"","autofocus":"","rows":"1"},model:{value:(_vm.justificativa),callback:function ($$v) {_vm.justificativa=$$v},expression:"justificativa"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.cabecalho,"items":_vm.apuracoes},scopedSlots:_vm._u([{key:"item.cod_planejamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.idAcao)+" ")]}},{key:"item.hierarquia",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hierarquiaAcao)+" ")]}},{key:"item.valor_acao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.valorAcao)+" ")]}},{key:"item.foco_acao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.focoAcao)+" ")]}},{key:"item.tipo_de_verba",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipoVerba)+" ")]}},{key:"item.valor_pagamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.vlrConfApuracao))+" ")]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.acaoModal(false,null)}}},[_vm._v(_vm._s(_vm.$t('label.nao')))]),(_vm.aprovar)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.aprovarApuracao(_vm.justificativa)}}},[_vm._v(_vm._s(_vm.$t('label.sim')))]):_vm._e(),(_vm.emAnalise && _vm.justificativa)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.enviarAnaliseApuracao(_vm.justificativa)}}},[_vm._v(_vm._s(_vm.$t('label.sim')))]):_vm._e(),(_vm.reprovar && _vm.justificativa)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.reprovarApuracao(_vm.justificativa)}}},[_vm._v(_vm._s(_vm.$t('label.sim')))]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }