var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CardPagamentoContainer"},[_c('v-container',{staticClass:"Container_Nivel2_Pagamento",attrs:{"fluid":"","px-0":"","py-2":""}},[_c('v-row',{staticClass:"mx-0",attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":("" + (_vm.$tc('label.pesquisar', 1))),"single-line":"","hide-details":"","clearable":""},on:{"input":_vm.filtrar},model:{value:(_vm.pesquisaCards),callback:function ($$v) {_vm.pesquisaCards=$$v},expression:"pesquisaCards"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","align-self":"center"}},[(_vm.canEdit && _vm.implantacao.indImportacaoLiquidacao)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0 pt-1",attrs:{"id":"btn_importar","icon":""},on:{"click":_vm.abrirModalImportacao}},on),[_c('v-icon',[_vm._v("backup")])],1)]}}],null,false,41145715)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.importar')))])]):_vm._e()],1)],1),_c('v-row',{staticClass:"mx-0",attrs:{"justify":"center"}},[_c('v-container',{staticClass:"Container_Cards",attrs:{"fluid":"","grid-list-md":""}},[_c('v-data-iterator',{attrs:{"items":_vm.lista,"options":_vm.pagination,"hide-default-footer":12 >= _vm.totalPage,"server-items-length":_vm.totalPage,"footer-props":{
            itemsPerPageOptions: _vm.rowsPerPageItems,
          }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',{staticClass:"mx-0",attrs:{"justify":"start"}},_vm._l((props.items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","sm":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{staticClass:"clickable Card_Pagamento",class:("Card_Pagamento_" + (_vm.isVisaoStatus ? item.status : 'SEM_STATUS') + " elevation-" + (hover ? 5 : 1)),attrs:{"fill-height":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-card-title',_vm._g({staticClass:"Card_Title pa-2 justify-center",on:{"click":function($event){return _vm.selectItem(item)}}},on),[_c('p',{staticStyle:{"text-align":"center"}},[_c('br'),_vm._v(_vm._s(item.focoId)+" "),_c('span',[_c('br'),_vm._v(_vm._s(_vm._f("truncate")(item.focoNome,28))+" "),_c('br'),_vm._v(_vm._s(item.codAcaoPrevia ? ("Cod. Planejamento Massivo: " + (item.codAcaoPrevia)) : ("Cod. Ação Tática: " + (item.codAcao)))+" "),_c('br'),_c('br'),_vm._v(_vm._s(_vm.$tc('label.periodo_apuracao', 1))+" "),_c('br'),_vm._v(_vm._s(item.dataInicio)+" à "+_vm._s(item.dataFim)+" "),_c('br'),_c('div',{staticClass:"headline font-weight-light",attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.getMoney(item.valor))+" ")])])])])]}}],null,true)},[((_vm.isVisaoStatus && (item.pagamentosAprovados === 0)
                        && (item.pagamentosReprovados === 0)
                        && (item.pagamentosCancelados === 0)) )?_c('span',[_vm._v(" Status: "+_vm._s(_vm.$tc('status_entidade.'+item.status.toLowerCase()))+" ")]):_vm._e(),((!_vm.isVisaoStatus && (item.pagamentosAprovados === 0)
                        && (item.pagamentosReprovados === 0)
                        && (item.pagamentosCancelados === 0)) )?_c('span',[_vm._v(" Ainda não existem pagamentos aprovados, reprovados ou cancelados ")]):_vm._e(),(((item.pagamentosAprovados === item.quantidadeAcoes)
                        && (item.quantidadeAcoes > 0)
                        && (item.pagamentosReprovados === 0)
                        && (item.pagamentosCancelados === 0)) )?_c('span',[_vm._v(" Status: Pagamentos Aprovados ")]):_vm._e(),(((item.pagamentosReprovados === item.quantidadeAcoes)
                        && (item.quantidadeAcoes > 0)
                        && (item.pagamentosAprovados === 0)
                        && (item.pagamentosCancelados === 0)) )?_c('span',[_vm._v(" Status: Pagamentos Reprovados ")]):_vm._e(),(((item.pagamentosCancelados === item.quantidadeAcoes)
                        && (item.quantidadeAcoes > 0)
                        && (item.pagamentosReprovados === 0)
                        && (item.pagamentosAprovados === 0)) )?_c('span',[_vm._v(" Status: Pagamentos Canceladas ")]):_vm._e(),(((item.pagamentosAprovados > 0)
                        && ((item.pagamentosAprovados !== item.quantidadeAcoes) ||
                            ((item.pagamentosReprovados > 0) || (item.pagamentosCancelados > 0)))
                        && (item.pagamentosAprovados >= item.pagamentosReprovados)
                        && (item.pagamentosAprovados >= item.pagamentosCancelados)) )?_c('span',[_vm._v(" Status: Parcialmente Aprovado "),((item.pagamentosReprovados > 0) || (item.pagamentosCancelados > 0))?_c('p',[_vm._v(" Existem apurações reprovadas ou canceladas ")]):_vm._e()]):_vm._e(),(((item.pagamentosReprovados > item.pagamentosAprovados)
                        && ((item.pagamentosReprovados !== item.quantidadeAcoes) || (item.pagamentosAprovados > 0))
                        && (item.pagamentosReprovados >= item.pagamentosCancelados)) )?_c('span',[_vm._v(" Status: Parcialmente Reprovado "),(item.pagamentosAprovados > 0)?_c('p',[_vm._v(" Existem apurações aprovadas ")]):_vm._e()]):_vm._e(),(((item.pagamentosCancelados > item.pagamentosAprovados)
                        && ((item.pagamentosCancelados !== item.quantidadeAcoes) || (item.pagamentosAprovados > 0))
                        && (item.pagamentosCancelados >= item.pagamentosReprovados)) )?_c('span',[_vm._v(" Status: Parcialmente Cancelado "),(item.pagamentosAprovados > 0)?_c('p',[_vm._v(" Existem apurações aprovadas ")]):_vm._e()]):_vm._e()]),_c('v-card-text',{staticClass:"Card_Content py-0",on:{"click":function($event){return _vm.selectItem(item)}}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[(_vm.isVisaoStatus)?_c('span',[_vm._v(" "+_vm._s(((item.quantidadeAcoes > 0 ? item.quantidadeAcoes : 1) + " " + (_vm.retornaTextoStatus(item.quantidadeAcoes, item.status))))+" ")]):_c('span',[_vm._v(" "+_vm._s(((item.quantidadeAcoes > 0 ? item.quantidadeAcoes : 1) + " " + (item.quantidadeAcoes > 1 ? 'Ações' : 'Ação')))+" ")])])],1)],1),(item.codAcaoPrevia)?_c('v-card-actions',{staticClass:"Card_Actions"},[(_vm.canAccessAprovMass && ((item.pagamentosAguardandoAprovacao > 0) || (item.pagamentosPendentes > 0)
                          || (item.pagamentosEmAnalise > 0) || (item.pagamentosVencidos > 0)))?_c('pagamento-acao-massiva-fluxo',{staticClass:"-3",attrs:{"idAcaoMassiva":item.codAcaoPrevia,"status":item.status},on:{"AprovacaoMassivaPagamento_sucesso":function($event){return _vm.reloadFluxoAtualizado()},"LimpaSelecionados_sucesso":function () {}}}):_vm._e()],1):_vm._e()],1)]}}],null,true)})],1)}),1)]}},{key:"no-data",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[_vm._v(" "+_vm._s(_vm.$t('label.tabela_sem_conteudo'))+" ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[_vm._v(" "+_vm._s(_vm.$t('label.tabela_sem_conteudo'))+" ")])]},proxy:true}])})],1)],1)],1),_c('confirm',{ref:"modalConfirmacao",attrs:{"message":_vm.mensagemConfirmacao},on:{"agree":_vm.validarAcao}}),_c('modal-importacao-pagamento',{ref:"modalImportacaoPagamento",on:{"RECARREGAR_PAGAMENTO":_vm.buscar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }